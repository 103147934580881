import enumRule from "./enum";
import pattern from "./pattern";
import range from "./range";
import required from "./required";
import type from "./type";
import whitespace from "./whitespace";
export default {
  required: required,
  whitespace: whitespace,
  type: type,
  range: range,
  enum: enumRule,
  pattern: pattern
};