/**
 * @see https://umijs.org/docs/max/access#access
 * */
/*export default function access(initialState: { currentUser?: API.CurrentUser } | undefined) {
  const { currentUser } = initialState ?? {};
  return {
    canAdmin: currentUser && currentUser.access === 'admin',
  };
}*/
/**
 * @see https://umijs.org/zh-CN/plugins/plugin-access
 * */

import check from '@/components/Authorized/CheckPermissions.js';
import { getAuthority } from '@/pages/utils/authority';

export default function access(initialState: { currentUser?: API.CurrentUser } | undefined) {
  //  console.log("access initialState: ", initialState);
  //  console.log("access getAuthority: ", getAuthority());
  const { currentUser } = initialState ?? {};
  return {
    canAdmin: currentUser && currentUser.access === 'admin',
    canInt: (vals) => {
      console.log('access initialState: ', initialState);
      console.log('access getAuthority: ', getAuthority());
      console.log('canInt vals: ', vals);
      let check_result = check(vals?.authority, true, false);
      console.log('canInt check_result: ', check_result);
      return check_result;
      //   return true;
    },
  };
}
