export default {
  'menu.works': 'Current MJ works',
  'menu.messages': 'All MJ works',
  'message.enter_prompt.required': 'Prompt message',
  'pages.login.accountRegister.tab': 'Registration',
  'pages.login.vkname.placeholder': 'VK Name',
  'pages.login.vkid.placeholder': 'VK Id',
  'pages.login.email.placeholder': 'Email (Username)',
  'pages.login.nickname.placeholder': 'Nickname',
  'pages.login.username.placeholder': 'Username',
  'pages.login.secret_word.placeholder': 'Secret word (from VK group)',
  'pages.login.accountLogin.tab': 'Login',
  'pages.login.Register': 'Регистрация',
  'pages.login.accountLogin.errorMessage': 'Incorrect email/password',
  'pages.login.failure': 'Login failed, please try again!',
  'pages.login.success': 'Login successful!',
  'pages.login.email.required': 'Please input your Email!',
  'pages.login.username.required': 'Please input your Username!',
  'pages.login.password.placeholder': 'Password',
  'pages.login.password.required': 'Please input your password!',
  'pages.login.secret_word.required': 'Please input Secret word (from VK group)',
  'pages.login.rememberMe': 'Remember me',
  'pages.login.forgotPassword': 'Forgot Password ?',
  'pages.login.submit': 'Login',
  'pages.login.Login': 'Login',
  'pages.login.confidential.required': 'You must agree to the Privacy Policy!',
  'pages.login.dogovor.required': 'You must agree to the Offer Agreement!',
  'pages.register.failure': 'Registration failure',
  'pages.welcome.link': 'Welcome'
};