import { Table, Image, Space, Flex, Button, Popconfirm, Input } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'umi';

const WsConnect = (props) => {
  const { dispatch, dc_login, dc_account, dc_settings, dc_ws_active, dc_ws_queue} = props;
  let socket = useRef(null);
  let queue_timeout = null;
  let send_queue = null;
  const [wsRows, setRows] = useState([]);
  const [wsRowsCount, setRowsCount] = useState(0);
  const [allQueue, setAllQueue] = useState(0);
  const [wsQueue, setQueue] = useState([]);
  const [wsActive, setWsActive] = useState('yellow');
  const [pingInterval, setPingInterval] = useState(null);
  //const [pingTimeout, setPingTimeout] = useState(null);
  const [socketStatus, setStatus] = useState('closing')

/*  useEffect(() => {
    console.log("New WS Status: " + socketStatus);
    console.log("Socket: " + window.WebSocket);
    console.log("Socket: ", socket);
    if (socketStatus == 'closing') {
        if(dc_login?.data?.auth_token && dc_account?.data?.login) {
            connectSocket(pingInterval,setPingInterval);
            setStatus('opening');
        } else {
            setTimeout(() => {
                if(dc_login?.data?.auth_token && dc_account?.data?.login) {
                    connectSocket(pingInterval,setPingInterval);
                    setStatus('opening');
                }
            }, 2000)
        }
    } else {
        console.log(dc_login, dc_account);
    }
  }, [socketStatus])
*/
  useEffect(() => {
    console.log("wsRows: ", wsRows);
  //  setRowsRender(CreateRows wsRow);
    dispatch({
        type: 'dc_ws_active/ws_set_active',
        payload: { wsRows },
    })
  }, [wsRowsCount]);

  useEffect(() => {
    console.log("Queue: ", wsQueue);
  //  clearTimeout(send_queue);
  //  setRowsRender(CreateRows wsRow);
  //  if (wsQueue.length > 0) {
  //      send_queue = setTimeout(() =>
  //          dispatch({
  //              type: 'dc_ws_queue/ws_set_queue',
  //              payload: { wsQueue },
  //          })
  //      , 2000)
  //  }
  }, [wsQueue]);
  
  useEffect(() => {
    console.log("wsQueue: ", wsQueue);
    console.log("wsRows: ", wsRows);
  }, [dc_ws_active, dc_ws_queue, allQueue]);

  useEffect(() => {
    console.log("dc_login: ", dc_login);
    console.log("dc_account: ", dc_account);
    console.log("socketStatus: ", socketStatus);
    if (socketStatus === 'closing') {
        if(dc_login?.data?.auth_token && dc_account?.data?.login) {
            connectSocket(pingInterval,setPingInterval);
            setStatus('opening');
        } else {
            setTimeout(() => {
                if(dc_login?.data?.auth_token && dc_account?.data?.login) {
                    connectSocket(pingInterval,setPingInterval);
                    setStatus('opening');
                }
            }, 2000)
        }
    }
  }, [dc_login, dc_account, socketStatus])

  return (
      <>
        { dc_account.data !== undefined ?
      <div style={{position: 'absolute', top: 10, right: 10, backgroundColor: wsActive, padding: 5, borderRadius: 5, fontWeight: 'bold'}}>
            {allQueue}
      </div>
            :
            <></>
        }
      </>
  )

function connectSocket(pingInterval,setPingInterval) {
    let pingTimeout = null;
    let wsRowsCountLocal = 0;
    if (socket?.current === null || socket?.current === undefined) {
        console.log("Socket: ", socket);
    } else {
        socket.close();
    }
    if ( dc_login?.data?.mj_server === "asia") {
        socket = new WebSocket(dc_settings?.discordAsiaWsUrl + "?login=" + dc_account?.data?.login + "&authtoken=" + dc_login?.data?.auth_token + "&type=discord");
    } else {
        socket = new WebSocket(dc_settings?.discordSpbWsUrl + "?login=" + dc_account?.data?.login + "&authtoken=" + dc_login?.data?.auth_token + "&type=discord");
    }
    console.log(socket, socket);
    socket.addEventListener('open', function () {
        console.log(socket, socket);
        console.log("socket.readyState",socket.readyState);
        if(socket.readyState === 1) {
            setPingInterval(setInterval(function(){
                setWsActive('green');
                console.log("socket.readyState",socket.readyState);
                if (socket.readyState === 3) {
                    socket.close();
                    setStatus('closing');
                } else {
                    socket.send('ping')
                    pingTimeout = setTimeout(() => {
                        setWsActive('red')}, 4000);
                }
            },30000))
        } else {
            if (socket.readyState === 3) {
                socket.close();
                setStatus('closing');
            }
            clearInterval(pingInterval);
        }
    });

    socket.onmessage = function (e) {
        if (e.data == "pong") {
            setWsActive('green');
            console.log(e.data, pingTimeout);
            if(pingTimeout !== null) {
                clearTimeout(pingTimeout);
            }
        } else {
            const data = JSON.parse(e.data);
            if(pingTimeout !== null) {
                clearTimeout(pingTimeout);
            }
            console.log(data);
            let newWsRows = wsRows;
            const MessageIdNew = data.message_id;
            if (data.type == "MESSAGE_CREATE") {
                let Check = 0;
                for(let i=0;i < newWsRows.length;i++){
                    let MessageIdOld = newWsRows[i].message_id;
                    if(MessageIdNew == MessageIdOld && data.content != "") {
                        Check = 1;
                    }
                }
                if (Check == 0) {
                    if (data.state == "Finished") {
                        setTimeout(() => {
                            if (dc_account.data) {
                                const data = { login: dc_account.data.login };
                                console.log(data);
                                console.log('GetMessages: ', data);
                                dispatch({
                                    type: 'dc_messages_day/refresh',
                                    payload: { data, type: 'get_user_messages_by_day' },
                                });
                            }
                        }, 5000)
                    } else {
                        newWsRows.push(data);
                        setRows(newWsRows);
                        wsRowsCountLocal += 1;
                        console.log(wsRowsCountLocal);
                        setRowsCount(wsRowsCountLocal);
                        console.log(wsRows);
                    }
                }
            }
            if (data.type == "MESSAGE_UPDATE") {
                console.log(newWsRows.length);
                for(let i=0;i < newWsRows.length;i++){
                    console.log(i);
                    console.log(newWsRows[i]);
                    let MessageIdOld = newWsRows[i].message_id;
                    if(MessageIdNew == MessageIdOld) {
                        newWsRows[i] = data;
                    }
                }
                setRows(newWsRows);
                wsRowsCountLocal += 1;
                setRowsCount(wsRowsCountLocal);
            }
            if (data.type == "MESSAGE_DELETE") {
                setTimeout(() => {
                    let DelRows = newWsRows;
                    console.log(DelRows);
                    console.log(DelRows.length);
                    for(let i=0;i < newWsRows.length;i++){
                        console.log(newWsRows[i]);
                        let MessageIdOld = newWsRows[i].message_id;
                        if(MessageIdNew == MessageIdOld) {
                            newWsRows.splice(i, 1);
                            DelRows = newWsRows;
                        }
                    }
                    console.log(DelRows);
                    console.log(DelRows.length);
                    setRows(newWsRows);
                    wsRowsCountLocal += 1;
                    console.log(wsRowsCountLocal);
                    setRowsCount(wsRowsCountLocal);
            }, 1000)
            }
            if (data.type == "queue") {
                console.log(dc_ws_queue);
            //    clearTimeout(send_queue);
            //    send_queue = setTimeout(() => {
            //        console.log("Send to Dispatch");
            //        dispatch({
            //            type: 'dc_ws_queue/ws_set_queue',
            //            payload: { wsQueueRows: wsQueue },
            //        })
            //    }, 2000)
                let data_arr = [];
                data_arr[0] = data;
                if (data.index == 0 || data.index == "0") {
                //    setQueue([])
                //    setQueue(wsQueue.push(data));
                    clearTimeout(queue_timeout);
                    console.log(data_arr);
                    dispatch({
                        type: 'dc_ws_queue/ws_set_queue',
                        payload: { wsQueueRows: data_arr },
                    })
                    queue_timeout = setTimeout(() => {
                        console.log("TimeOut Work");
                        dispatch({
                            type: 'dc_ws_queue/ws_set_queue',
                            payload: { wsQueueRows: [] },
                        })
                    }, 35000)
                } else {
                    setTimeout(() => {
                        //setQueue(wsQueue.push(data));
                        dispatch({
                            type: 'dc_ws_queue/ws_update_queue',
                            payload: { wsQueueRows: data_arr },
                        })
                    }, 100 * data.index)
                }
            }
            if(data.type == "queue_all"){
                setAllQueue(data.count);
            }
            
        }
    }

    socket.onclose = function (e) {
        if(typeof pingInterval !== "undefined") {
            clearInterval(pingInterval);
        }
        setTimeout(() => {
            setStatus('closing');
            setWsActive("red");
        }, 2000);
    }

    socket.onerror = function (err) {
        if(typeof pingInterval !== "undefined") {
            clearInterval(pingInterval);
        }
        socket.close();
    }
  }
}

export default connect(({ dc_login, dc_account, dc_settings, }) => ({
  dc_login,
  dc_account,
  dc_settings,
}))(WsConnect);
