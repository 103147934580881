import { getDvaApp } from 'umi';
import { getAccount } from '../services/discord';
import { history } from '@umijs/max';

export function setUserLogin(payload) {
  return localStorage.setItem('userLogin', JSON.stringify(payload));
}

const DCAccountModel = {
  namespace: 'dc_account',

  state: {},

  effects: {
    *set_from_session(payload, { put }) {
      console.log("dc_account set_from_session");
      const response = JSON.parse(sessionStorage.getItem('dc_account'));
      localStorage.setItem('userLogin', JSON.stringify(response.data));
      yield put({
        type: 'update',
        payload: response,
      });
      getDvaApp()._store.dispatch({ type: 'dc_authority/refresh', payload: {} });
//      if(!payload.data?.page){
//        history.push('/welcome');
//      }
    },
    *refresh({ payload }, { call, put }) {
      console.log('IAMMM  dc_account/refresh payload: ', payload);
      const response = yield call(getAccount, payload);
      console.log(response);
      sessionStorage.setItem('dc_account', JSON.stringify(response));
      localStorage.setItem('userLogin', JSON.stringify(response.data));
      yield put({
        type: 'update',
        payload: response,
      });
      getDvaApp()._store.dispatch({ type: 'dc_authority/refresh', payload: {} });
//      history.push('/welcome');
    },
    *flush(_, { put }) {
      console.log("dc_account Flush");
      yield put({
        type: 'update',
        payload: {},
      });
    //  sessionStorage.setItem('dc_account', JSON.stringify({}));
    },
  },

  reducers: {
    update(state, { payload }) {
      setUserLogin(payload);
      return { ...payload };
    },
  },
};

export default DCAccountModel;
