//import { getQueue } from '../services/discord';

const Model = {
  namespace: 'dc_ws_queue',

  state: {},

  effects: {
    *ws_update_queue({ payload }, { put }) {
      console.log("Update: ", payload);
      yield put({
        type: 'update',
        payload: {
          data: payload.wsQueueRows
        }
      });
    },
    *ws_set_queue({ payload }, { put }) {
      console.log("Set", payload);
      yield put({
        type: 'set',
        payload: {
          data: payload.wsQueueRows
        }
      });
    },
  },

  reducers: {
    update(state, { payload }) {
      console.log("Update State: ", state.data);
      let data = state.data;
      if(typeof data === "undefined") {
        if(typeof payload.data !== "undefined") {
          data = [payload.data[0]];
        } else {
          data = []
        }
      } else {
        data.push(payload.data[0]);
      }
    //  state.data = data;
      console.log("Update State: ", state.data, data);
      return { data };
    },
    set(state, { payload }) {
      console.log("Set State: ", state);
      let data = payload.data;
      return { data };
    },
  },
};

export default Model;
