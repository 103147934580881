export default {
  moneySymbol: 'Kč',
  deleteThisLine: 'Smazat tento řádek',
  copyThisLine: 'Kopírovat tento řádek',
  form: {
    lightFilter: {
      more: 'Víc',
      clear: 'Vymazat',
      confirm: 'Potvrdit',
      itemUnit: 'Položky'
    }
  },
  tableForm: {
    search: 'Dotaz',
    reset: 'Resetovat',
    submit: 'Odeslat',
    collapsed: 'Zvětšit',
    expand: 'Zmenšit',
    inputPlaceholder: 'Zadejte prosím',
    selectPlaceholder: 'Vyberte prosím'
  },
  alert: {
    clear: 'Vymazat',
    selected: 'Vybraný',
    item: 'Položka'
  },
  pagination: {
    total: {
      range: ' ',
      total: 'z',
      item: 'položek'
    }
  },
  tableToolBar: {
    leftPin: 'Připnout doleva',
    rightPin: 'Připnout doprava',
    noPin: 'Odepnuto',
    leftFixedTitle: 'Fixováno nalevo',
    rightFixedTitle: 'Fixováno napravo',
    noFixedTitle: 'Neopraveno',
    reset: 'Resetovat',
    columnDisplay: 'Zobrazení sloupců',
    columnSetting: 'Nastavení',
    fullScreen: 'Celá obrazovka',
    exitFullScreen: 'Ukončete celou obrazovku',
    reload: 'Obnovit',
    density: 'Hustota',
    densityDefault: 'Výchozí',
    densityLarger: 'Větší',
    densityMiddle: 'Střední',
    densitySmall: 'Kompaktní'
  },
  stepsForm: {
    next: 'Další',
    prev: 'Předchozí',
    submit: 'Dokončit'
  },
  loginForm: {
    submitText: 'Přihlásit se'
  },
  editableTable: {
    onlyOneLineEditor: 'Upravit lze pouze jeden řádek',
    action: {
      save: 'Uložit',
      cancel: 'Zrušit',
      delete: 'Vymazat',
      add: 'přidat řádek dat'
    }
  },
  switch: {
    open: 'otevřít',
    close: 'zavřít'
  }
};