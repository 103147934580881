export default {
  moneySymbol: 'zł',
  form: {
    lightFilter: {
      more: 'Więcej',
      clear: 'Wyczyść',
      confirm: 'Potwierdź',
      itemUnit: 'Ilość'
    }
  },
  tableForm: {
    search: 'Szukaj',
    reset: 'Reset',
    submit: 'Zatwierdź',
    collapsed: 'Pokaż wiecej',
    expand: 'Pokaż mniej',
    inputPlaceholder: 'Proszę podać',
    selectPlaceholder: 'Proszę wybrać'
  },
  alert: {
    clear: 'Wyczyść',
    selected: 'Wybrane',
    item: 'Wpis'
  },
  pagination: {
    total: {
      range: ' ',
      total: 'z',
      item: 'Wpisów'
    }
  },
  tableToolBar: {
    leftPin: 'Przypnij do lewej',
    rightPin: 'Przypnij do prawej',
    noPin: 'Odepnij',
    leftFixedTitle: 'Przypięte do lewej',
    rightFixedTitle: 'Przypięte do prawej',
    noFixedTitle: 'Nieprzypięte',
    reset: 'Reset',
    columnDisplay: 'Wyświetlane wiersze',
    columnSetting: 'Ustawienia',
    fullScreen: 'Pełen ekran',
    exitFullScreen: 'Zamknij pełen ekran',
    reload: 'Odśwież',
    density: 'Odstęp',
    densityDefault: 'Standard',
    densityLarger: 'Wiekszy',
    densityMiddle: 'Sredni',
    densitySmall: 'Kompaktowy'
  },
  stepsForm: {
    next: 'Weiter',
    prev: 'Zurück',
    submit: 'Abschließen'
  },
  loginForm: {
    submitText: 'Zaloguj się'
  },
  editableTable: {
    action: {
      save: 'Zapisać',
      cancel: 'Anuluj',
      delete: 'Usunąć',
      add: 'dodawanie wiersza danych'
    }
  },
  switch: {
    open: 'otwierać',
    close: 'zamykać'
  }
};