export default {
  moneySymbol: '฿',
  deleteThisLine: 'ลบบรรทัดนี้',
  copyThisLine: 'คัดลอกบรรทัดนี้',
  form: {
    lightFilter: {
      more: 'มากกว่า',
      clear: 'ชัดเจน',
      confirm: 'ยืนยัน',
      itemUnit: 'รายการ'
    }
  },
  tableForm: {
    search: 'สอบถาม',
    reset: 'รีเซ็ต',
    submit: 'ส่ง',
    collapsed: 'ขยาย',
    expand: 'ทรุด',
    inputPlaceholder: 'กรุณาป้อน',
    selectPlaceholder: 'โปรดเลือก'
  },
  alert: {
    clear: 'ชัดเจน',
    selected: 'เลือกแล้ว',
    item: 'รายการ'
  },
  pagination: {
    total: {
      range: ' ',
      total: 'ของ',
      item: 'รายการ'
    }
  },
  tableToolBar: {
    leftPin: 'ปักหมุดไปทางซ้าย',
    rightPin: 'ปักหมุดไปทางขวา',
    noPin: 'เลิกตรึงแล้ว',
    leftFixedTitle: 'แก้ไขด้านซ้าย',
    rightFixedTitle: 'แก้ไขด้านขวา',
    noFixedTitle: 'ไม่คงที่',
    reset: 'รีเซ็ต',
    columnDisplay: 'การแสดงคอลัมน์',
    columnSetting: 'การตั้งค่า',
    fullScreen: 'เต็มจอ',
    exitFullScreen: 'ออกจากโหมดเต็มหน้าจอ',
    reload: 'รีเฟรช',
    density: 'ความหนาแน่น',
    densityDefault: 'ค่าเริ่มต้น',
    densityLarger: 'ขนาดใหญ่ขึ้น',
    densityMiddle: 'กลาง',
    densitySmall: 'กะทัดรัด'
  },
  stepsForm: {
    next: 'ถัดไป',
    prev: 'ก่อนหน้า',
    submit: 'เสร็จ'
  },
  loginForm: {
    submitText: 'เข้าสู่ระบบ'
  },
  editableTable: {
    onlyOneLineEditor: 'แก้ไขได้เพียงบรรทัดเดียวเท่านั้น',
    action: {
      save: 'บันทึก',
      cancel: 'ยกเลิก',
      delete: 'ลบ',
      add: 'เพิ่มแถวของข้อมูล'
    }
  },
  switch: {
    open: 'เปิด',
    close: 'ปิด'
  }
};