import { getDvaApp } from 'umi';
import request from '../utils/request_dc';
import { dateToGregorian } from '../utils/subroutine';

export const JSON_HEADERS = () => {
  const redux_state = getDvaApp()._store.getState();
  console.log("redux_state: ", redux_state);
  return {
    'Content-Type': 'application/json; charset=utf-8',
    Accept: 'application/json',
    'X-Auth-Token': redux_state.dc_login?.data?.auth_token,
  };
};

export const PDF_HEADERS = () => {
  const redux_state = getDvaApp()._store.getState();
  return {
    'Content-Type': 'application/json; charset=utf-8',
    Accept: 'application/pdf',
    'X-Auth-Token': redux_state.dc_login.auth_token,
  };
};

export const CSV_HEADERS = () => {
  const redux_state = getDvaApp()._store.getState();
  return {
    'Content-Type': 'application/json; charset=utf-8',
    Accept: 'text/csv',
    'X-Auth-Token': redux_state.dc_login.auth_token,
  };
};

export const ANY_HEADERS = (props) => {
  const redux_state = getDvaApp()._store.getState();
  return {
    'Content-Type': props.content_type,
    Accept: props.accept,
    'X-Auth-Token': redux_state.dc_login.auth_token,
  };
};

export const MAYBE_SUPER_DUPER = () => {
  const redux_state = getDvaApp()._store.getState();
  if (redux_state.dc_account.data) {
    if (redux_state.dc_account.data.type === admin) {
      return redux_state.dc_account.data.superduper_admin === true;
    }
    return false;
  }
  return false;
};

export function timeRange(params) {
  const lookup_field = params.lookup_field ? params.lookup_field : 'created';
  const TO_TIMESTAMP = params.created_to ? params.created_to : dateToGregorian(new Date());
  //  const FROM_TIMESTAMP = params.created_from ? params.created_from : TO_TIMESTAMP - 7776000;
  const FROM_TIMESTAMP = params.created_from ? params.created_from : TO_TIMESTAMP - 75600;
  return `?${lookup_field}_from=${FROM_TIMESTAMP}&${lookup_field}_to=${TO_TIMESTAMP}`;
}

export async function dc_user_auth(params) {
  const redux_state = getDvaApp()._store.getState();
//  let API_URL_V2 = redux_state.dc_settings.discordSpbUrlV2;
  let API_URL_V2 = redux_state.dc_settings.discordAsiaUrlV2;
  if(redux_state.dc_login?.data?.mj_server == "asia") {
    API_URL_V2 = redux_state.dc_settings.discordAsiaUrlV2;
  }
  const result = request(`${API_URL_V2}?type=signin`, {
    method: 'POST',
    data: params,
  });
  return result;
}

export async function dc_register(params) {
  const redux_state = getDvaApp()._store.getState();
//  let API_URL_V2 = redux_state.dc_settings.dispcordSpbUrlV2;
  let API_URL_V2 = redux_state.dc_settings.discordAsiaUrlV2;
  if(redux_state.dc_login?.data?.mj_server == "asia") {
    API_URL_V2 = redux_state.dc_settings.discordAsiaUrlV2;
  }
  const result = request(`${API_URL_V2}?type=register`, {
    method: 'POST',
    data: params,
  });
  return result;
}

export async function customRequest(params) {
  const redux_state = getDvaApp()._store.getState();
//  let API_URL_V2 = redux_state.dc_settings.discordSpbUrlV2;
  let API_URL_V2 = redux_state.dc_settings.discordAsiaUrlV2;
  if(redux_state.dc_login?.data?.mj_server === "asia") {
    console.log("change asia");
    API_URL_V2 = redux_state.dc_settings.discordAsiaUrlV2;
  }
  console.log(API_URL_V2);
  const result = request(`${API_URL_V2}?type=mj_request`, {
    method: 'POST',
    data: params,
    headers: JSON_HEADERS(),
  });
  return result;
}

export async function thaiRequest(params) {
  const redux_state = getDvaApp()._store.getState();
//  let API_URL_V2 = redux_state.dc_settings.discordSpbUrlV2;
  let API_URL_V2 = redux_state.dc_settings.discordAsiaThaiUrlV2;
  if(redux_state.dc_login?.data?.mj_server === "asia") {
    API_URL_V2 = redux_state.dc_settings.discordAsiaThaiUrlV2;
  }
  const result = request(`${API_URL_V2}?type=${params.type}`, {
    method: params.method,
    data: params,
    headers: JSON_HEADERS(),
  });
  return result;
}

export async function chatgptRequest(params) {
  const redux_state = getDvaApp()._store.getState();
//  let API_URL_V2 = redux_state.dc_settings.discordSpbUrlV2;
  let API_URL_V2 = redux_state.dc_settings.discordAsiaUrlV2;
  if(redux_state.dc_login?.data?.mj_server === "asia") {
    console.log("change asia");
    API_URL_V2 = redux_state.dc_settings.discordAsiaUrlV2;
  }
  console.log(API_URL_V2);
  const result = request(`${API_URL_V2}?type=chatgpt`, {
    method: 'POST',
    data: params,
    headers: JSON_HEADERS(),
  });
  return result;
}

export async function messageAdvRequest(params) {
  const redux_state = getDvaApp()._store.getState();
//  let API_URL_V2 = redux_state.dc_settings.discordSpbUrlV2;
  let API_URL_V2 = redux_state.dc_settings.discordAsiaUrlV2;
  if(redux_state.dc_login?.data?.mj_server === "asia") {
    console.log("change asia");
    API_URL_V2 = redux_state.dc_settings.discordAsiaUrlV2;
  }
  console.log(API_URL_V2);
  const result = request(`${API_URL_V2}?type=${params.type}`, {
    method: 'POST',
    data: params,
    headers: JSON_HEADERS(),
  });
  return result;
}

export async function getAccount(params) {
  const redux_state = getDvaApp()._store.getState();
//  let API_URL_V2 = redux_state.dc_settings.discordSpbUrlV2;
  let API_URL_V2 = redux_state.dc_settings.discordAsiaUrlV2;
  if(redux_state.dc_login?.data?.mj_server == "asia") {
    API_URL_V2 = redux_state.dc_settings.discordAsiaUrlV2;
  }
  const result = request(`${API_URL_V2}?type=get_user`, {
    method: 'POST',
    data: params,
    headers: JSON_HEADERS(),
  });

  return result;
}

export async function getMessages(params) {
  const redux_state = getDvaApp()._store.getState();
//  let API_URL_V2 = redux_state.dc_settings.discordSpbUrlV2;
  let API_URL_V2 = redux_state.dc_settings.discordAsiaUrlV2;
  if(redux_state.dc_login?.data?.mj_server == "asia") {
    API_URL_V2 = redux_state.dc_settings.discordAsiaUrlV2;
  }
  const result = request(`${API_URL_V2}`, {
    method: 'POST',
    data: params,
    headers: JSON_HEADERS(),
  });
  return result;
}

export async function getQueue(params) {
  const redux_state = getDvaApp()._store.getState();
//  let API_URL_V2 = redux_state.dc_settings.discordSpbUrlV2;
  let API_URL_V2 = redux_state.dc_settings.discordAsiaUrlV2;
  if(redux_state.dc_login?.data?.mj_server == "asia") {
    API_URL_V2 = redux_state.dc_settings.discordAsiaUrlV2;
  }
  const result = request(`${API_URL_V2}`, {
    method: 'POST',
    data: params,
    headers: JSON_HEADERS(),
  });
  return result;
}

export async function getUserQueue(params) {
  const redux_state = getDvaApp()._store.getState();
//  let API_URL_V2 = redux_state.dc_settings.discordSpbUrlV2;
  let API_URL_V2 = redux_state.dc_settings.discordAsiaUrlV2;
  if(redux_state.dc_login?.data?.mj_server == "asia") {
    API_URL_V2 = redux_state.dc_settings.discordAsiaUrlV2;
  }
  const result = request(`${API_URL_V2}?type=get_user_queue`, {
    method: 'POST',
    data: params,
    headers: JSON_HEADERS(),
  });
  return result;
}

export async function getAllQueue(params) {
  const redux_state = getDvaApp()._store.getState();
//  let API_URL_V2 = redux_state.dc_settings.discordSpbUrlV2;
  let API_URL_V2 = redux_state.dc_settings.discordAsiaUrlV2;
  if(redux_state.dc_login?.data?.mj_server == "asia") {
    API_URL_V2 = redux_state.dc_settings.discordAsiaUrlV2;
  }
  const result = request(`${API_URL_V2}?type=get_all_queue`, {
    method: 'POST',
    data: params,
    headers: JSON_HEADERS(),
  });
  return result;
}

export async function getUser(params) {
  const redux_state = getDvaApp()._store.getState();
//  let API_URL_V2 = redux_state.dc_settings.discordSpbUrlV2;
  let API_URL_V2 = redux_state.dc_settings.discordAsiaUrlV2;
  if(redux_state.dc_login?.data?.mj_server == "asia") {
    API_URL_V2 = redux_state.dc_settings.discordAsiaUrlV2;
  }
  const url = `${API_URL_V2}/accounts/${params.account_id}/users/${params.owner_id}`;
  return request(url, {
    method: 'GET',
    headers: JSON_HEADERS(),
  });
}

export async function checkCurrentAuthToken() {
  const redux_state = getDvaApp()._store.getState();
//  let API_URL_V2 = redux_state.dc_settings.discordSpbUrlV2;
  let API_URL_V2 = redux_state.dc_settings.discordAsiaUrlV2;
  if(redux_state.dc_login?.data?.mj_server == "asia") {
    API_URL_V2 = redux_state.dc_settings.discordAsiaUrlV2;
  }
  const url = `${API_URL_V2}?type=tokeninfo`;
  return request(url, {
    method: 'GET',
    headers: JSON_HEADERS(),
  });
}

