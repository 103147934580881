export default {
  moneySymbol: '₮',
  form: {
    lightFilter: {
      more: 'Илүү',
      clear: 'Цэвэрлэх',
      confirm: 'Баталгаажуулах',
      itemUnit: 'Нэгжүүд'
    }
  },
  tableForm: {
    search: 'Хайх',
    reset: 'Шинэчлэх',
    submit: 'Илгээх',
    collapsed: 'Өргөтгөх',
    expand: 'Хураах',
    inputPlaceholder: 'Утга оруулна уу',
    selectPlaceholder: 'Утга сонгоно уу'
  },
  alert: {
    clear: 'Цэвэрлэх',
    selected: 'Сонгогдсон',
    item: 'Нэгж'
  },
  pagination: {
    total: {
      range: ' ',
      total: 'Нийт',
      item: 'мөр'
    }
  },
  tableToolBar: {
    leftPin: 'Зүүн тийш бэхлэх',
    rightPin: 'Баруун тийш бэхлэх',
    noPin: 'Бэхлэхгүй',
    leftFixedTitle: 'Зүүн зэрэгцүүлэх',
    rightFixedTitle: 'Баруун зэрэгцүүлэх',
    noFixedTitle: 'Зэрэгцүүлэхгүй',
    reset: 'Шинэчлэх',
    columnDisplay: 'Баганаар харуулах',
    columnSetting: 'Тохиргоо',
    fullScreen: 'Бүтэн дэлгэцээр',
    exitFullScreen: 'Бүтэн дэлгэц цуцлах',
    reload: 'Шинэчлэх',
    density: 'Хэмжээ',
    densityDefault: 'Хэвийн',
    densityLarger: 'Том',
    densityMiddle: 'Дунд',
    densitySmall: 'Жижиг'
  },
  stepsForm: {
    next: 'Дараах',
    prev: 'Өмнөх',
    submit: 'Дуусгах'
  },
  loginForm: {
    submitText: 'Нэвтрэх'
  },
  editableTable: {
    action: {
      save: 'Хадгалах',
      cancel: 'Цуцлах',
      delete: 'Устгах',
      add: 'Мөр нэмэх'
    }
  },
  switch: {
    open: 'Нээх',
    close: 'Хаах'
  }
};