export default {
  moneySymbol: '₺',
  form: {
    lightFilter: {
      more: 'Daha Fazla',
      clear: 'Temizle',
      confirm: 'Onayla',
      itemUnit: 'Öğeler'
    }
  },
  tableForm: {
    search: 'Filtrele',
    reset: 'Sıfırla',
    submit: 'Gönder',
    collapsed: 'Daha fazla',
    expand: 'Daha az',
    inputPlaceholder: 'Filtrelemek için bir değer girin',
    selectPlaceholder: 'Filtrelemek için bir değer seçin'
  },
  alert: {
    clear: 'Temizle',
    selected: 'Seçili',
    item: 'Öğe'
  },
  pagination: {
    total: {
      range: ' ',
      total: 'Toplam',
      item: 'Öğe'
    }
  },
  tableToolBar: {
    leftPin: 'Sola sabitle',
    rightPin: 'Sağa sabitle',
    noPin: 'Sabitlemeyi kaldır',
    leftFixedTitle: 'Sola sabitlendi',
    rightFixedTitle: 'Sağa sabitlendi',
    noFixedTitle: 'Sabitlenmedi',
    reset: 'Sıfırla',
    columnDisplay: 'Kolon Görünümü',
    columnSetting: 'Ayarlar',
    fullScreen: 'Tam Ekran',
    exitFullScreen: 'Tam Ekrandan Çık',
    reload: 'Yenile',
    density: 'Kalınlık',
    densityDefault: 'Varsayılan',
    densityLarger: 'Büyük',
    densityMiddle: 'Orta',
    densitySmall: 'Küçük'
  },
  stepsForm: {
    next: 'Sıradaki',
    prev: 'Önceki',
    submit: 'Gönder'
  },
  loginForm: {
    submitText: 'Giriş Yap'
  },
  editableTable: {
    action: {
      save: 'Kaydet',
      cancel: 'Vazgeç',
      delete: 'Sil',
      add: 'foegje in rige gegevens ta'
    }
  },
  switch: {
    open: 'açık',
    close: 'kapatmak'
  }
};