//import { getQueue } from '../services/discord';

const Model = {
    namespace: 'dc_ws_active',
  
    state: {},
  
    effects: {
      *ws_set_active({ payload }, { put }) {
        yield put({
          type: 'update',
          payload:{
            data: payload.wsRows
          }
        });
      },
    },
  
    reducers: {
      update(state, { payload }) {
        return { ...payload };
      },
    },
  };
  
  export default Model;
  