export default {
  moneySymbol: '€',
  deleteThisLine: 'Odstrániť tento riadok',
  copyThisLine: 'Skopírujte tento riadok',
  form: {
    lightFilter: {
      more: 'Viac',
      clear: 'Vyčistiť',
      confirm: 'Potvrďte',
      itemUnit: 'Položky'
    }
  },
  tableForm: {
    search: 'Vyhladať',
    reset: 'Resetovať',
    submit: 'Odoslať',
    collapsed: 'Rozbaliť',
    expand: 'Zbaliť',
    inputPlaceholder: 'Prosím, zadajte',
    selectPlaceholder: 'Prosím, vyberte'
  },
  alert: {
    clear: 'Vyčistiť',
    selected: 'Vybraný',
    item: 'Položka'
  },
  pagination: {
    total: {
      range: ' ',
      total: 'z',
      item: 'položiek'
    }
  },
  tableToolBar: {
    leftPin: 'Pripnúť vľavo',
    rightPin: 'Pripnúť vpravo',
    noPin: 'Odopnuté',
    leftFixedTitle: 'Fixované na ľavo',
    rightFixedTitle: 'Fixované na pravo',
    noFixedTitle: 'Nefixované',
    reset: 'Resetovať',
    columnDisplay: 'Zobrazenie stĺpcov',
    columnSetting: 'Nastavenia',
    fullScreen: 'Celá obrazovka',
    exitFullScreen: 'Ukončiť celú obrazovku',
    reload: 'Obnoviť',
    density: 'Hustota',
    densityDefault: 'Predvolené',
    densityLarger: 'Väčšie',
    densityMiddle: 'Stredné',
    densitySmall: 'Kompaktné'
  },
  stepsForm: {
    next: 'Ďalšie',
    prev: 'Predchádzajúce',
    submit: 'Potvrdiť'
  },
  loginForm: {
    submitText: 'Prihlásiť sa'
  },
  editableTable: {
    onlyOneLineEditor: 'Upravovať možno iba jeden riadok',
    action: {
      save: 'Uložiť',
      cancel: 'Zrušiť',
      delete: 'Odstrániť',
      add: 'pridať riadok údajov'
    }
  },
  switch: {
    open: 'otvoriť',
    close: 'zavrieť'
  }
};