import { getDvaApp } from 'umi';
import { checkCurrentAuthToken, dc_user_auth, dc_register } from '../services/discord';
import { notification } from 'antd';

const DCLoginModel = {
  namespace: 'dc_login',

  state: {
    ...JSON.parse(localStorage.getItem('userLogin')),
  },

  effects: {
    *set_from_session(payload, { put }) {
      console.log("dc_login set_from_session");
      const response = JSON.parse(sessionStorage.getItem('dc_login'));
      console.log("payload: ", payload);
      console.log("response", response);
      if (response?.status === 'success') {
        yield put({
          type: 'changeLoginStatus',
          payload: response,
        });
    //    getDvaApp()._store.dispatch({
    //      type: 'dc_account/refresh',
    //      payload: { data: { account_id: response.data.account_id, login: payload.data.login }},
    //    });
      }
    },
    *login({ payload }, { call, put }) {
      console.log("dc_login login");
      const response = yield call(dc_user_auth, payload);
      console.log(response?.status);
      if (response?.status === 'success') {
        //const now = new Date();
        response.auth_before = Date.now() + 28800000;
        sessionStorage.setItem('dc_login', JSON.stringify(response));
        console.log(response);
        yield put({
          type: 'changeLoginStatus',
          payload: response,
        });
    //    getDvaApp()._store.dispatch({
    //      type: 'dc_user/refresh',
    //      payload: { account_id: response.data.owner_id, owner_id: response.data.owner_id },
    //    });
        getDvaApp()._store.dispatch({
          type: 'dc_account/refresh',
          payload: { data: { account_id: response.data.account_id, login: payload.data.login }},
        });
      }
    },
    *register({ payload }, { call, put }) {
      console.log(payload);
      let response = yield call(dc_register, payload);
      console.log(response)
      console.log(response?.status)
      if (response?.status === 'success') {
        notification.success({
          message: response?.status.charAt(0).toUpperCase() + response?.status.slice(1),
          description: response?.data?.payload,
        });
        const data = { secret: payload.data.secret, login: payload.data.login };
        console.log('DataIAM1: ', data);
        getDvaApp()._store.dispatch({
          type: 'dc_login/login',
          payload: { data, type: 'signin' },
        });   
      } else {
        notification.error({
          message: response?.status.charAt(0).toUpperCase() + response?.status.slice(1),
          description: response?.data?.payload,
        }); 
      }
    },
    *check_auth(_, { call, put }) {
      console.log("dc_login check_auth");
      const response = yield call(checkCurrentAuthToken);
      console.log(response);
      if (response?.status !== 'success') {
        yield put({
          type: 'changeLoginStatus',
          payload: { currentAuthority: null },
        });
        getDvaApp()._store.dispatch({ type: 'dc_login/logout' });
        getDvaApp()._store.dispatch({ type: 'dc_account/flush' });
      }
    },
    *logout(_, { put }) {
      console.log("dc_login logout");
      yield put({
        type: 'changeLoginStatus',
        payload: { currentAuthority: null },
      });
    //  getDvaApp()._store.dispatch({ type: 'dc_login/flush' });
    //  sessionStorage.setItem('dc_login', JSON.stringify({ currentAuthority: null }));
      localStorage.removeItem('userLogin');
      sessionStorage.clear();
      getDvaApp()._store.dispatch({ type: 'dc_account/flush' });
      getDvaApp()._store.dispatch({ type: 'dc_authority/flush' });
      window.location.replace('/');
    },
  },

  reducers: {
    changeLoginStatus(state, { payload }) {
      return { ...payload };
    },
  },
};

export default DCLoginModel;
