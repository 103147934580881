import isEqual from 'lodash.isequal';
import { getDvaApp } from 'umi';
import { history } from '@umijs/max';

const AuthorityModel = {
  namespace: 'dc_authority',

  state: { currentAuthority: [] },

  effects: {
    *refresh(_, { put }) {
      try {
        const redux_state = getDvaApp()._store.getState();
        console.log(redux_state)
        let authority = [];
        const session = JSON.parse(sessionStorage.getItem('dc_account'));

        let user_type = [];
        if (redux_state.dc_account?.data?.authority !== undefined) {
          user_type = redux_state.dc_account?.data?.authority;
        } else if ( session.data?.user_type !== undefined) {
          user_type = session.data?.authority;
        };
        console.log("UserType: ", user_type);
        if (redux_state.dc_account?.data?.user_type === 'admin' ||
            session.data?.user_type === 'admin'
        ) {
          authority.push('admin');
        };
        if (redux_state.dc_account?.data?.user_type === 'user' ||
          session.data?.user_type === 'user') {
            authority.push('user');
        };
        if (user_type.length > 0) {
          authority = user_type;
        }

        const account_id = redux_state.dc_account?.data ? [redux_state.dc_account?.data?.account_id] : [];
        console.log('!!!!!! INSIDE account_id', account_id);
        console.log(
          '!!!!!! INSIDE redux_state.dc_account?.data?.id',
          redux_state.dc_account?.data?.id,
        );

        // One more dirty hack 17e3d20d5343462f15f7563f3b6f388e
        
        console.log("authority: ", authority)
        if (!isEqual(authority, redux_state.dc_authority.currentAuthority)) {
          console.log("redux_authority: ", redux_state.dc_authority.currentAuthority)
          sessionStorage.setItem('dc_authority', JSON.stringify({ currentAuthority: authority }));
          yield put({
            type: 'update',
            payload: { currentAuthority: authority },
          });
  //        history.push('/welcome');
        } else {
  //        history.push('/');
        }
      } catch (e) {
        const redux_state = getDvaApp()._store.getState();
        console.log("redux_authority: ", redux_state.dc_authority.currentAuthority)
        sessionStorage.setItem('dc_authority', JSON.stringify({ currentAuthority: [] }));
        yield put({
          type: 'update',
          payload: { currentAuthority: [] },
        });
        console.log('authority catch error update: ', e);
  //      history.push('/');
      }
    },
    *flush(_, { put }) {
      yield put({
        type: 'update',
        payload: { currentAuthority: [] },
      });
    },
  },

  reducers: {
    update(state, { payload }) {
      console.log('authority about to reduce update: ', payload);
      return { ...payload };
    },
  },
};

export default AuthorityModel;
