export default {
  moneySymbol: '₪',
  deleteThisLine: 'מחק שורה זו',
  copyThisLine: 'העתק שורה זו',
  form: {
    lightFilter: {
      more: 'יותר',
      clear: 'נקה',
      confirm: 'אישור',
      itemUnit: 'פריטים'
    }
  },
  tableForm: {
    search: 'חיפוש',
    reset: 'איפוס',
    submit: 'שלח',
    collapsed: 'הרחב',
    expand: 'כווץ',
    inputPlaceholder: 'אנא הכנס',
    selectPlaceholder: 'אנא בחר'
  },
  alert: {
    clear: 'נקה',
    selected: 'נבחר',
    item: 'פריט'
  },
  pagination: {
    total: {
      range: ' ',
      total: 'מתוך',
      item: 'פריטים'
    }
  },
  tableToolBar: {
    leftPin: 'הצמד לשמאל',
    rightPin: 'הצמד לימין',
    noPin: 'לא מצורף',
    leftFixedTitle: 'מוצמד לשמאל',
    rightFixedTitle: 'מוצמד לימין',
    noFixedTitle: 'לא מוצמד',
    reset: 'איפוס',
    columnDisplay: 'תצוגת עמודות',
    columnSetting: 'הגדרות',
    fullScreen: 'מסך מלא',
    exitFullScreen: 'צא ממסך מלא',
    reload: 'רענן',
    density: 'רזולוציה',
    densityDefault: 'ברירת מחדל',
    densityLarger: 'גדול',
    densityMiddle: 'בינוני',
    densitySmall: 'קטן'
  },
  stepsForm: {
    next: 'הבא',
    prev: 'קודם',
    submit: 'סיום'
  },
  loginForm: {
    submitText: 'כניסה'
  },
  editableTable: {
    onlyOneLineEditor: 'ניתן לערוך רק שורה אחת',
    action: {
      save: 'שמור',
      cancel: 'ביטול',
      delete: 'מחיקה',
      add: 'הוסף שורת נתונים'
    }
  },
  switch: {
    open: 'פתח',
    close: 'סגור'
  }
};