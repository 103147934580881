export default {
  'app.setting.pagestyle': '스타일 설정',
  'app.setting.pagestyle.dark': '다크 모드',
  'app.setting.pagestyle.light': '라이트 모드',
  'app.setting.content-width': '컨텐츠 너비',
  'app.setting.content-width.fixed': '고정',
  'app.setting.content-width.fluid': '흐름',
  'app.setting.themecolor': '테마 색상',
  'app.setting.themecolor.dust': 'Dust Red',
  'app.setting.themecolor.volcano': 'Volcano',
  'app.setting.themecolor.sunset': 'Sunset Orange',
  'app.setting.themecolor.cyan': 'Cyan',
  'app.setting.themecolor.green': 'Polar Green',
  'app.setting.themecolor.techBlue': 'Tech Blu (default)',
  'app.setting.themecolor.daybreak': 'Daybreak Blue',
  'app.setting.themecolor.geekblue': 'Geek Blue',
  'app.setting.themecolor.purple': 'Golden Purple',
  'app.setting.navigationmode': '네비게이션 모드',
  'app.setting.regionalsettings': '영역별 설정',
  'app.setting.regionalsettings.header': '헤더',
  'app.setting.regionalsettings.menu': '메뉴',
  'app.setting.regionalsettings.footer': '바닥글',
  'app.setting.regionalsettings.menuHeader': '메뉴 헤더',
  'app.setting.sidemenu': '메뉴 사이드 배치',
  'app.setting.topmenu': '메뉴 상단 배치',
  'app.setting.mixmenu': '혼합형 배치',
  'app.setting.splitMenus': '메뉴 분리',
  'app.setting.fixedheader': '헤더 고정',
  'app.setting.fixedsidebar': '사이드바 고정',
  'app.setting.fixedsidebar.hint': "'메뉴 사이드 배치'를 선택했을 때 동작함",
  'app.setting.hideheader': '스크롤 중 헤더 감추기',
  'app.setting.hideheader.hint': "'헤더 감추기 옵션'을 선택했을 때 동작함",
  'app.setting.othersettings': '다른 설정',
  'app.setting.weakmode': '고대비 모드',
  'app.setting.copy': '설정값 복사',
  'app.setting.loading': '테마 로딩 중',
  'app.setting.copyinfo': '복사 성공. src/models/settings.js에 있는 defaultSettings를 교체해 주세요.',
  'app.setting.production.hint': '설정 판넬은 개발 환경에서만 보여집니다. 직접 수동으로 변경바랍니다.'
};