import { customRequest } from '../services/discord';
import { notification } from "antd";

const Model = {
  namespace: 'dc_customRequest',

  state: {},

  effects: {
    *imagine({ payload }, { call, put }) {
      console.log(payload);
      const data = {
        "type": "imagine",
        "login": payload.login,
        "data": 
        {
          "type":2,
          "data":
            {
                "name":"imagine",
                "type":1,
                "options":
                    [{
                        "type":3,
                        "name":"prompt",
                        "value":payload.values.prompt
                    }],
                "attachments": []
            },
        },
        "attachments": payload.values.attachments,
        "content": payload.values.prompt,
        "parameters": payload.values.parameters
      };
      const response = yield call(customRequest, data);
      if (response?.status === 'success') {
        notification.success({
          message: response?.status.charAt(0).toUpperCase() + response?.status.slice(1),
          description: response?.data?.payload,
        });      
      } else {
        notification.error({
          message: response?.status.charAt(0).toUpperCase() + response?.status.slice(1),
          description: response?.data?.payload,
        }); 
      }
    },
    *describe({ payload }, { call, put }) {
      console.log(payload);
      const data = {
        "type": "describe",
        "login": payload.login,
        "data": 
        {
          "type":2,
          "data":
            {
                "name":"describe",
                "type":1,
                "options":
                    [{
                        "type":3,
                        "name":"link",
                        "value":"url"
                    }],
                "attachments": []
            },
        },
        "attachments": payload.values.attachments
      };
      const response = yield call(customRequest, data);
      if (response?.status === 'success') {
        notification.success({
          message: response?.status.charAt(0).toUpperCase() + response?.status.slice(1),
          description: response?.data?.payload,
        });      
      } else {
        notification.error({
          message: response?.status.charAt(0).toUpperCase() + response?.status.slice(1),
          description: response?.data?.payload,
        }); 
      }
    },
    *command({ payload }, { call, put }) {
      const data = {
        "type": "command",
        "login": payload.login,
        "content": payload.content,
        "mj_client": payload.mj_client,
        "isremix": payload.isremix,
        "data": 
        {
            "type":3,
            "message_id": payload.message_id,
            "data":
                {
                    "component_type":2,
                    "custom_id": payload.custom_id
                }
        }
      };
      console.log(data);
      const response = yield call(customRequest, data);
      if (response?.status === 'success') {
        notification.success({
          message: response?.status.charAt(0).toUpperCase() + response?.status.slice(1),
          description: response?.data?.payload,
        });      
      } else {
        notification.error({
          message: response?.status.charAt(0).toUpperCase() + response?.status.slice(1),
          description: response?.data?.payload,
        }); 
      }
    },
    *flush(_, { put }) {
      yield put({
        type: 'update',
        payload: {},
      });
    },
  },

  reducers: {
    update(state, { payload }) {
      return { ...payload };
    },
  },
};

export default Model;
