import { getMessages } from '../services/discord';

const Model = {
  namespace: 'dc_messages_day',

  state: {},

  effects: {
    *refresh({ payload }, { call, put }) {
      console.log("Payload: ", payload);
      const response = yield call(getMessages, payload);
      console.log("Response", response);
      if (response?.status === 'success') {
        yield put({
          type: 'update',
          payload: response,
        });
      } else {
        yield put({
          type: 'update',
          payload: { data: [], status: response.status },
        });
      }
    },
    *flush(_, { put }) {
      yield put({
        type: 'update',
        payload: {},
      });
    },
  },

  reducers: {
    update(state, { payload }) {
      return { ...payload };
    },
  },
};

export default Model;
