import { Footer, Question, SelectLang, AvatarDropdown, AvatarName } from '@/components';
import type { Settings as LayoutSettings } from '@ant-design/pro-components';
//import type { Settings as DiscordSettings } from '@ant-design/pro-components';
import { SettingDrawer } from '@ant-design/pro-components';
import type { RunTimeLayoutConfig } from '@umijs/max';
import { useModel, history, Link } from '@umijs/max';
import defaultSettings from '../config/defaultSettings';
import { errorConfig } from './requestErrorConfig';
import { useDispatch } from 'react-redux';
//import { getAccount as queryCurrentUser } from '@/services/discord';
import React, { useEffect } from 'react';
import WsConnect from './components/ws_connect';
const isDev = process.env.NODE_ENV === 'development';
const loginPath = '/login';

/**
 * @see  https://umijs.org/zh-CN/plugins/plugin-initial-state
 * */
export async function getInitialState(): Promise<{
  settings?: Partial<LayoutSettings>;
  currentUser?: API.CurrentUser;
  loading?: boolean;
  dc_login?: any;
  dc_account?: any;
  dc_authority?: any;
  fetchUserInfo?: () => Promise<API.CurrentUser | undefined>;
}> {
  const fetchUserInfo = async () => {
    try {
      console.log("fetchUserInfo");
      let localData = localStorage.getItem('userLogin');
      if (localData !== null) {
        return JSON.parse(localData);
      } else {
        return JSON.parse("{}");
      }
    } catch (error) {
    //  history.push(loginPath);
    }
    return undefined;
  };

  const dc_login_sess = sessionStorage.getItem('dc_login');
  let dc_login;
  if(dc_login_sess === null) {
    dc_login = JSON.parse("{}"); 
  } else {
    if(JSON.parse(dc_login_sess)?.auth_before > Date.now()) {
      dc_login = JSON.parse(dc_login_sess);
    } else {
      dc_login = JSON.parse("{}");
    }
  }
  let dc_account;
  const dc_account_sess = sessionStorage.getItem('dc_account');
  if(dc_account_sess === null) {
    dc_account = JSON.parse("{}");
  } else {
    if(dc_login?.auth_before > Date.now()) {
      dc_account = JSON.parse(dc_account_sess);
    } else {
      dc_account = JSON.parse("{}");
    }
  }
  let dc_authority;
  const dc_authority_sess = sessionStorage.getItem('dc_authority');
  if(dc_authority_sess === null) {
    dc_authority = JSON.parse("{}");
  } else {
    if(dc_login?.auth_before > Date.now()) {
      dc_authority = JSON.parse(dc_authority_sess);
    } else {
      dc_authority = JSON.parse("{}");
    }
  }
  const dispatch = useDispatch();
  if (dc_login?.data?.login && dc_login?.auth_before > Date.now()) {
    console.log("dc_login/set_from_session: ", dc_login);
    dispatch({
      type: 'dc_login/set_from_session',
      payload: { login: dc_login?.data?.login },
    });
  }
  if (dc_login?.data && dc_login?.auth_before > Date.now()) {
    console.log('dc_login: ', dc_login);
      if (dc_account?.data) {
        console.log('dc_account/set_from_session: ', dc_account);
        dispatch({
          type: 'dc_account/set_from_session',
          payload: { login: dc_login?.data?.login },
        });
      }
      if (!dc_account?.data) {
        console.log('dc_account/refresh: ', dc_account);
        dispatch({
          type: 'dc_account/refresh',
          payload: { login: dc_login?.data?.login },
        });
      }
  }
  // 如果不是登录页面，执行
  const { location } = history;
  if (location.pathname !== loginPath) {
    const currentUser = await fetchUserInfo();
    console.log(currentUser);
    return {
      fetchUserInfo,
      currentUser,
      settings: defaultSettings as Partial<LayoutSettings>,
      dc_login: dc_login,
      dc_account: dc_account,
    };
  }
  console.log(fetchUserInfo);
  return {
    fetchUserInfo,
    settings: defaultSettings as Partial<LayoutSettings>,
    dc_login: dc_login,
    dc_account: dc_account,
    dc_authority: dc_authority,
  };
}

// ProLayout 支持的api https://procomponents.ant.design/components/layout
export const layout: RunTimeLayoutConfig = ({ initialState, setInitialState }) => {
  console.log(initialState);
  const dc_login_sess = sessionStorage.getItem('dc_login');
  let dc_login;
  const dispatch = useDispatch();
  if(dc_login_sess === null) {
    dc_login = JSON.parse("{}"); 
  } else {
    if(JSON.parse(dc_login_sess)?.auth_before > Date.now()) {
      dc_login = JSON.parse(dc_login_sess);
      if (initialState === undefined) {
        dispatch({
          type: 'dc_login/set_from_session',
          payload: { login: dc_login?.data?.login },
        });
      }
    } else {
      dc_login = JSON.parse("{}");
    }
  }
  let dc_account;
  let currentUser = {};
  const dc_account_sess = sessionStorage.getItem('dc_account');
  if(dc_account_sess === null) {
    dc_account = JSON.parse("{}"); 
  } else {
    if(dc_login?.auth_before > Date.now()) {
      dc_account = JSON.parse(dc_account_sess);
      currentUser = dc_account.data;
      if (initialState === undefined) {
        dispatch({
          type: 'dc_account/set_from_session',
          payload: { login: dc_login?.data?.login },
        });
      }
    } else {
      dc_account = JSON.parse("{}");
    }
  }
  let dc_authority;
  const dc_authority_sess = sessionStorage.getItem('dc_authority');
  if(dc_authority_sess === null) {
    dc_authority = JSON.parse("{}"); 
  } else {
    if(dc_login?.auth_before > Date.now()) {
      dc_authority = JSON.parse(dc_authority_sess);
    } else {
      dc_authority = JSON.parse("{}");
    }
  }
  useEffect(() => {
    setInitialState((s) => ({
        ...s,
        currentUser,
      }));
  },[initialState])
  
  console.log(currentUser);
  return {
    actionsRender: () => [<Question key="doc" />, <SelectLang key="SelectLang" />],
    avatarProps: {
      src: initialState?.currentUser?.avatar,
      title: <AvatarName />,
      render: (_, avatarChildren) => {
        return <AvatarDropdown>{avatarChildren}</AvatarDropdown>;
      },
    },
//    waterMarkProps: {
//      content: initialState?.currentUser?.name,
//    },
    footerRender: () => <Footer />,
    onPageChange: () => {
      const { location } = history;
      console.log("initialState: ", initialState);
      // 如果没有登录，重定向到 login
    //  if ((!initialState?.dc_login || !initialState?.dc_account || initialState?.dc_login?.auth_before <= Date.now() || !initialState?.currentUser) && location.pathname !== loginPath) {
//      if ((!dc_login.data || !dc_account.data || dc_login?.auth_before <= Date.now()) && location.pathname !== loginPath) {    
//        console.log("initialState If: ", initialState);
//        history.push(loginPath);
//      }
    },
    bgLayoutImgList: [
      {
        src: 'https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/D2LWSqNny4sAAAAAAAAAAAAAFl94AQBr',
        left: 85,
        bottom: 100,
        height: '303px',
      },
      {
        src: 'https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/C2TWRpJpiC0AAAAAAAAAAAAAFl94AQBr',
        bottom: -68,
        right: -45,
        height: '303px',
      },
      {
        src: 'https://mdn.alipayobjects.com/yuyan_qk0oxh/afts/img/F6vSTbj8KpYAAAAAAAAAAAAAFl94AQBr',
        bottom: 0,
        left: 0,
        width: '331px',
      },
    ],
    links: [],
    menuHeaderRender: undefined,
    // 自定义 403 页面
    unAccessible: () => {
      console.log(403);
      history.push("/");
      window.location.replace("https://services.multiart.pro");
      return (
        <meta http-equiv="refresh" content="0; url=/" />
      )
    },
    // 增加一个 loading 的状态
    childrenRender: (children) => {
      // if (initialState?.loading) return <PageLoading />;
      return (
        <>
          {children}
          {isDev && (
            <SettingDrawer
            disableUrlParams
            enableDarkTheme
            settings={defaultSettings}
            onSettingChange={(settings) => {
              setInitialState((preInitialState) => ({
                ...preInitialState,
                settings,
                dc_login,
                dc_account,
                dc_authority,
                currentUser,
              }));
            }}
            />
          )}
          <WsConnect />
        </>
      );
    },
    ...initialState?.settings,
    ...dc_login,
    ...dc_account,
    ...dc_authority,
    ...currentUser,
    ...defaultSettings,
  };
};

/**
 * @name request 配置，可以配置错误处理
 * 它基于 axios 和 ahooks 的 useRequest 提供了一套统一的网络请求和错误处理方案。
 * @doc https://umijs.org/docs/max/request#配置
 */
export const request = {
  ...errorConfig,
};
