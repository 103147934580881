export default {
  'pages.layouts.userLayout.title':
    'Ant Design is the most influential web design specification in Xihu district',
  'pages.welcome.alertMessage': 'Faster and stronger heavy-duty components have been released.',
  'pages.404.subTitle': 'Sorry, the page you visited does not exist.',
  'pages.404.buttonText': 'Back Home',
  'pages.403.subTitle': 'Sorry, the page you visited does not permit.',
  'pages.403.buttonText': 'Back Home',
  'pages.admin.subPage.title': 'This page can only be viewed by Admin',
  'pages.admin.subPage.alertMessage':
    'Umi ui is now released, welcome to use npm run ui to start the experience.' 
};