// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import model_1 from '/home/work_project/multiart_services/src/models/dc_account';
import model_2 from '/home/work_project/multiart_services/src/models/dc_authority';
import model_3 from '/home/work_project/multiart_services/src/models/dc_customRequest';
import model_4 from '/home/work_project/multiart_services/src/models/dc_global';
import model_5 from '/home/work_project/multiart_services/src/models/dc_login';
import model_6 from '/home/work_project/multiart_services/src/models/dc_messages_day';
import model_7 from '/home/work_project/multiart_services/src/models/dc_messages';
import model_8 from '/home/work_project/multiart_services/src/models/dc_setting';
import model_9 from '/home/work_project/multiart_services/src/models/dc_user';
import model_10 from '/home/work_project/multiart_services/src/models/dc_ws_active';
import model_11 from '/home/work_project/multiart_services/src/models/dc_ws_queue';

export const models = {
model_1: { namespace: 'dc_account', model: model_1 },
model_2: { namespace: 'dc_authority', model: model_2 },
model_3: { namespace: 'dc_customRequest', model: model_3 },
model_4: { namespace: 'dc_global', model: model_4 },
model_5: { namespace: 'dc_login', model: model_5 },
model_6: { namespace: 'dc_messages_day', model: model_6 },
model_7: { namespace: 'dc_messages', model: model_7 },
model_8: { namespace: 'dc_setting', model: model_8 },
model_9: { namespace: 'dc_user', model: model_9 },
model_10: { namespace: 'dc_ws_active', model: model_10 },
model_11: { namespace: 'dc_ws_queue', model: model_11 },
} as const
