export default {
  'menu.works': 'Текущие работы',
  'menu.messages': 'История',
  'message.enter_prompt.required': 'Prompt message',
  'pages.login.accountRegister.tab': 'Регистрация',
  'pages.login.vkname.placeholder': 'Имя Вконтакте',
  'pages.login.vkid.placeholder': 'ID Вконтакте',
  'pages.login.email.placeholder': 'Email (Имя пользователя)',
  'pages.login.username.placeholder': 'Имя пользователя',
  'pages.login.nickname.placeholder': 'Nickname',
  'pages.login.secret_word.placeholder': 'Секретное слово (из группы Вконтакте)',
  'pages.login.Login': 'Вход',
  'pages.login.Register': 'Регистрация',
  'pages.login.confidential.required': 'Вы должны согласиться c Правилами Конфиденциальности!',
  'pages.login.dogovor.required': 'Вы должны согласиться c Договором-Офертой!',
  'pages.login.accountLogin.tab': 'Вход',
  'pages.login.accountLogin.errorMessage': 'Incorrect email/password',
  'pages.login.failure': 'Login failed, please try again!',
  'pages.login.success': 'Login successful!',
  'pages.login.email.required': 'Please input your Email!',
  'pages.login.username.required': 'Введите Ваше Имя пользователя!',
  'pages.login.password.placeholder': 'Password',
  'pages.login.password.required': 'Please input your password!',
  'pages.login.secret_word.required': 'Введите Секретное слово (из группы Вконтакте)',
  'pages.login.rememberMe': 'Remember me',
  'pages.login.forgotPassword': 'Forgot Password ?',
  'pages.login.submit': 'Login',
  'pages.register.failure': 'Ошибка регистрации',
  'pages.welcome.link': 'Welcome'
};