import { ProLayoutProps } from '@ant-design/pro-components';

/**
 * @name
 */
const Settings = {
  devServer: {
    https: true,
  },
  navTheme: "realDark",
  // 拂晓蓝
  colorPrimary: '#49aa19',
  layout: 'side',
  contentWidth: 'Fluid',
  fixedHeader: false,
  fixSiderbar: true,
  colorWeak: false,
  title: 'Multiart Services',
  pwa: true,
  discordSpbUrlV2: 'https://services.multiart.pro/react_api',
  discordSpbThaiUrlV2: 'https://services.multiart.pro/thai_api',
  discordSpbWsUrl: 'wss://services.multiart.pro/discord',
  discordAsiaUrlV2: 'https://asia.multiart.pro/react_api',
  discordAsiaThaiUrlV2: 'https://asia.multiart.pro/thai_api',
  discordAsiaWsUrl: 'wss://asia.multiart.pro/discord',
  //logo: 'https://services.multiart.pro/logo.png',
  logo: 'https://services.multiart.pro/logo.png',
  iconfontUrl: '',
  token: {
    // 参见ts声明，demo 见文档，通过token 修改样式
    //https://procomponents.ant.design/components/layout#%E9%80%9A%E8%BF%87-token-%E4%BF%AE%E6%94%B9%E6%A0%B7%E5%BC%8F
  },
};

export default Settings;
